<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Legal",
  metaInfo: function () {
    return {
      title: "Stoferno.gr | Legal",
      meta: [{ name: "description", content: "legals, terms and conditions" }],
    };
  },
  data() {
    return {};
  },
};
</script>

<style></style>
